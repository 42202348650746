import React from "react";

const Pagination = ({ 
  totalPages = 0, 
  activePage = 1, 
  setPage,
  visiblePagesAround = 2 // Number of pages to show on each side of active page
}) => {
  // Function to format page numbers with leading zeros
  const formatPageNumber = (pageNumber) => {
    const totalDigits = totalPages.toString().length;
    return pageNumber.toString().padStart(totalDigits, "0");
  };

  const renderPageButton = (pageNumber, isActive = false) => (
    <div
      key={pageNumber}
      className={`page-button margin ${isActive ? "active_page" : ""}`}
      onClick={() => setPage(pageNumber)}
    >
      {formatPageNumber(pageNumber)}
    </div>
  );
  
  const renderEllipsis = (key) => (
    <div key={`ellipsis-${key}`} className="page-button margin">
      ...
    </div>
  );
  
  // Show all pages if there are few of them
  if (totalPages <= visiblePagesAround * 2 + 3) {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map(page => 
      renderPageButton(page, page === activePage)
    );
  }
  
  const buttons = [];
  
  // Always show first page
  buttons.push(renderPageButton(1, activePage === 1));
  
  // For pages near the beginning
  if (activePage <= visiblePagesAround + 3) {
    // Show additional pages at beginning to compensate for missing ellipsis
    for (let i = 2; i <= visiblePagesAround + 5; i++) {
      if (i <= totalPages - 1) {
        buttons.push(renderPageButton(i, i === activePage));
      }
    }
    
    // Add ellipsis before last page only if needed
    if (totalPages > visiblePagesAround + 6) {
      buttons.push(renderEllipsis("right"));
    }
  } 
  // For pages near the end
  else if (activePage >= totalPages - (visiblePagesAround + 2)) {
    // Add ellipsis after first page
    buttons.push(renderEllipsis("left"));
    
    // Show additional pages at end to compensate for missing ellipsis
    for (let i = totalPages - (visiblePagesAround + 5); i < totalPages; i++) {
      if (i > 1) {
        buttons.push(renderPageButton(i, i === activePage));
      }
    }
  } 
  // For pages in the middle
  else {
    // Add ellipsis after first page
    buttons.push(renderEllipsis("left"));
    
    // Show pages around active page
    for (let i = activePage - visiblePagesAround; i <= activePage + visiblePagesAround; i++) {
      buttons.push(renderPageButton(i, i === activePage));
    }
    
    // Add ellipsis before last page
    buttons.push(renderEllipsis("right"));
  }
  
  // Always show last page
  buttons.push(renderPageButton(totalPages, activePage === totalPages));
  
  return buttons;
};

export default Pagination;
