import { adminHost } from "../index";

const BASE_PATH = process.env.REACT_APP_API_URL;

export const AdminService = {
    getPartnerPayouts: async ({ page, created_at }) => {
        return await adminHost.get(`${BASE_PATH}partner/api/withdrawal/from_admin/`, {
            params: {
                page,
                created_at,
                page_size: 20
            }
        }).then(res => {
            return res.data;
        })
    },

    getPairs: async ({ page, page_size = 10, search }) => {
        return await adminHost.get(`${BASE_PATH}/coin/api/coin_network/`, {
            params: {
                page, page_size, search
            }
        }).then(res => res.data)
    },

    switchIsMicroservice: async ({id, value}) => {
        return await adminHost.patch(`${BASE_PATH}/coin/api/coin_network/${id}/`, {
            is_microservice: value
        })
    },

    updateParnterPayout: async ({id, coin_amount, txid, status }) => {
        return await adminHost.patch(`${BASE_PATH}/partner/api/withdrawal/${id}/`, {coin_amount, txid, status}).then(res => res.data)
    },

    getDataReportByPeriod: async (date_from, date_to) => {
        return await adminHost.post(`${BASE_PATH}/statistic/api/date-range/`, {date_from, date_to})
    }
}