import React, { useState, useEffect } from "react";
import { host } from "../../http";
import icons from "../../assets/icons/coin_map.json";
import SeeMore from "../../assets/images/seemore.svg"
import Electro from "../../assets/images/electro.svg"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdminService } from "../../http/services/AdminService";
import PaginatedTable from "../ui/PaginatedTable";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";
import ManualUpdate from "../modals/ManualUpdate";
import UpdatePartnerPayout from "../modals/UpdatePartnerPayout";
import Pagination from "../ui/Pagination";

const tableHeaders = [
  { key: 'withdrawal_time', label: 'Time', valueFormatter: (value) => new Date(value).toLocaleString() },
  { key: 'usdt_amount', label: 'Sum', valueFormatter: (value) => `${value} USDT` },
  { key: 'coin', label: 'Coin' },
  { key: 'platform', label: 'Platform' },
  { key: 'address', label: 'Address' },
]

const PartnersS = ({options}) => {
  const [startDate, setStartDate] = useState(null)

  //EXCHANGES TABLE
  const [exchangesList, setExchangesList] = useState(null);
  const [exchCurrPage, setExchCurrPage] = useState(1);
  const [exchTotalPages, setExchTotalPages] = useState(null);
  const [itemToUpd, setItemToUpd] = useState(null);
  const [isManualUpdOpen, setIsManualUpdOpen] = useState(false);

  const statuses = {
    1: "Pending",
    2: "Success",
    3: "Failed"
  }

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table">
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  useEffect(() => {
    AdminService.getPartnerPayouts({page: exchCurrPage, created_at: startDate}).then(res => setExchangesList(res))
  }, [exchCurrPage, startDate])
  
  return (
    <div className="right_block">
    <ul className="navbar">
      
      

      {/* <input className="datepicker" placeholder="Select date" type="text" id="datepicker" /> */}
      <DatePicker
        isClearable
        className="datepicker"
        placeholderText="Select date"
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={(date) => {
          console.log(date.toJSON().split('T')[0])
          setStartDate(date.toJSON().split('T')[0])
        }}
      />
      <button id="admin-pnl-btn">
        <img src={Electro} alt="" />
      </button>
    </ul>
    <div className="content_block">
    <div className="content_block">
        <table className="table">
          <tr className="th_names">
            <th className="radius">Time</th>
            <th>ID</th>
            <th>Status</th>
            <th>Coin</th>
            <th>Amount</th>
            <th>Network</th>
            <th>TxID</th>
            <th>Address</th>
          </tr>

          {exchangesList?.results
            ?.slice()
            ?.map((item) => {
              return (
                <tr className="content_table">
                  <td>
                    <div className="time">{new Date(item?.created_at).toLocaleString("uk-UA").split(".").join("/")}</div>
                  </td>
                  <td>
                    <div className="url_block" style={{ alignItems: "center" }}>
                      <div
                        className="Url copy-element"
                        onClick={() => {
                          navigator.clipboard.writeText(item?.uid);
                          // setAlertIsOpen(true);
                        }}
                      >
                        {item?.uid?.substr(0, 6) + "..." + item?.uid?.substr(item?.uid?.length - 5, item?.uid?.length)}
                      </div>
                      <div className="market">{}</div>
                    </div>
                  </td>
                  <td className="padding">
                    <div className={item?.status ? `status status${item?.status.toUpperCase()}` : "status"}>{item?.status.toUpperCase()}</div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">
                      {item?.coin_amount ? +item?.coin_amount : 0} {item?.coin}
                    </div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">
                      {item?.usdt_amount} USDT
                    </div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">
                      {item.network}
                    </div>
                  </td>
                  <td className="padding">
                    <div
                      className="part_profit copy-element"
                      onClick={() => {
                        navigator.clipboard.writeText(item?.txid);
                        // setAlertIsOpen(true);
                      }}
                    >
                      {item?.txid?.substr(0, 6) + "..." + item?.txid?.substr(item?.txid?.length - 5, item?.txid?.length)}
                    </div>
                  </td>
                  <td className="padding">
                    <div
                      className="part_profit copy-element"
                      onClick={() => {
                        navigator.clipboard.writeText(item?.address);
                        // setAlertIsOpen(true);
                      }}
                    >
                      {item?.address?.substr(0, 6) + "..." + item?.address?.substr(item?.address?.length - 5, item?.address?.length)}
                    </div>
                  </td>
                  <div className="item_control">
                    <button
                      style={{ background: "#2c57ec" }}
                      onClick={() => {
                        setItemToUpd(item);
                        setIsManualUpdOpen(true);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </tr>
              );
            })}
          {renderEmptyRows(exchangesList?.results?.length)}
        </table>
        <div className="slidepages" style={{ userSelect: "none" }}>
          <div
            className="arrowleft margin"
            onClick={() => {
              exchangesList?.next && setExchCurrPage((prev) => prev - 1);
            }}
          >
            <img src={arrow_left} alt="" />
          </div>
          <Pagination totalPages={exchangesList?.total_pages} activePage={exchCurrPage} setPage={setExchCurrPage} />
          <div
            className="arrowright margin"
            onClick={() => {
              exchangesList?.previous && setExchCurrPage((prev) => prev + 1);
            }}
          >
            <img src={arrow_right} alt="" />
          </div>
        </div>
      </div>
    </div>

    {isManualUpdOpen ? (
        <UpdatePartnerPayout
          onClose={() => {
            setIsManualUpdOpen(false);
            setExchCurrPage(prev => prev)
            AdminService.getPartnerPayouts({page: exchCurrPage, created_at: startDate}).then(res => setExchangesList(res))
          }}
          item={itemToUpd}
          statuses={Object.fromEntries(Object.entries(statuses).slice(1))}
        />
      ) : null}
  </div>
  )
}


export default PartnersS;